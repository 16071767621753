import React, { useEffect, useState } from "react";
import { isAfter } from "date-fns";
import Image from 'components/common/Image';

import { LinkAnchor } from "components/common/LinkAnchor";
import Link from "components/common/Link";

// add new local image icon below and set name as imgLocal in tile-card.json
import logoFringeWorld from "images/fringe-world-festival/logo-fringeworld.png";
import logoTelethon from "images/banners/telethon-community-cinema.png";
import logoPgolf from "images/banners/perth-garden-festival.svg";
import logoGFWS from "images/banners/good-food-wine-show.svg";

const LogoComponents = {
  logoFringeWorld: logoFringeWorld,
  logoTelethon: logoTelethon,
  logoPgolf: logoPgolf,
  logoGFWS: logoGFWS,
};

// ?setCurrentTime=2024-01-16T10:44:05
function showTile(data, dateTimeParam) {

  const isNonPrd = process.env.GATSBY_ACTIVE_ENV !== "prd";
  const currentDate = (isNonPrd && dateTimeParam) ? new Date(dateTimeParam) : new Date();

  let isDisplay = true;
  if (data.displayStarts !== undefined) {
    const dateStr = isNonPrd
      ? data.displayStartsNonProd.split("-")
      : data.displayStarts.split("-");

    const bannerStart = new Date(
      dateStr[0],
      dateStr[1] - 1,
      dateStr[2],
      dateStr[3] ? dateStr[3] : "",
      dateStr[4] ? dateStr[4] : ""
    );

    isDisplay = isAfter(currentDate, bannerStart); // display if start date is in the past
  }

  if (data.displayEnds === undefined)
    // no end date
    return isDisplay;

  if (isDisplay) {
    const dateStr = isNonPrd
      ? data.displayEndsNonProd.split("-")
      : data.displayEnds.split("-");
    const bannerEnd = new Date(
      dateStr[0],
      dateStr[1] - 1,
      dateStr[2],
      dateStr[3] ? dateStr[3] : "",
      dateStr[4] ? dateStr[4] : ""
    );

    isDisplay = isAfter(bannerEnd, currentDate); // display if end date is in the future
  }

  return isDisplay;
}

function TileCardBody(props) {
  const LogoComponent =
    props.imgLocal !== undefined ? LogoComponents[props.imgLocal] : null; // variable must start with upper case

  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        {/* need to separate */}
        {props.imgLocal && (
            <img
            className="mer-panel__image"
            src={LogoComponent}
            height={props.title ? 32 : 50}
            alt={props.imgLocalAlt}
            />
        )}

        {props.svgIcon && <Image className="my-0 mer-panel__image"
                                 src={`/icons/flowmoji/flowmoji-${props.svgIcon}.svg`}
                                 style={{'width':'20%', 'max-width':'3.5em'}}
                                 alt={props.title} />
        }
        <h5 class="my-0 mer-pl-de" style={{'color':'inherit'}}>{props.title}</h5>
      </div>
    </React.Fragment>
  );
}

function TileCard(props) {
  const [visibleCards, setVisibleCards] = useState([]);

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const dateTimeParam = params.get('setCurrentTime');

    setVisibleCards(
      props.data.content.map(data => ({
        ...data,
        isVisible: showTile(data, dateTimeParam ? dateTimeParam : ''),
      }))
    );
  }, [props.data]);


  if (visibleCards.length === 0) {
    return null;
  }



  const defaultLinkClass = "mer-card mdc-card mer-py-sm mer-px-sm d-flex";

  return visibleCards.map(data => {
    const isLinkAnchor = data.link.includes("#");
    const isImgLocal = data.imgLocal !== undefined;
    
    const cardClasses = data.isVisible
      ? "col-10 col-sm-6 col-md-6 col-lg-3"
      : "col-10 col-sm-6 col-md-6 col-lg-3 d-none";

    return (
      <div className={cardClasses} key={data.id}>
        {isImgLocal && (
          <Link text={data.title} link={data.link} linkClass={defaultLinkClass}>
            <TileCardBody
              id={data.id}
              svgIcon={data.svgIcon}
              title={data.title}
              imgLocal={data.imgLocal}
              imgLocalAlt={data.imgLocalAlt}
            />
          </Link>
        )}

        {!isImgLocal && (
          <>
            {isLinkAnchor && (
              <LinkAnchor
                to={data.link}
                className={defaultLinkClass}
                title="Search again?"
                stripHash={true}
                offset={-100}
              >
                <TileCardBody svgIcon={data.svgIcon} title={data.title} />
              </LinkAnchor>
            )}
            {!isLinkAnchor && (
              <Link
                text={data.title}
                link={data.link}
                linkClass={defaultLinkClass}
              >
                <TileCardBody svgIcon={data.svgIcon} title={data.title} />
              </Link>
            )}
          </>
        )}
      </div>
    );
  });
}

export default TileCard;
