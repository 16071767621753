import React from 'react';
import Image from 'components/common/Image';
import LayoutPanel from 'components/layout/layoutPanel';
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';
import racLogo from 'images/rac/natural-gas/logo-rac.png';


function HomeProductHighlight (props) {

    return (
        <LayoutPanel background={props.panelBackground}
                     padding={props.panelPadding}>
            <div className="container">
                <div className="row justify-content-center align-items-start mer-text--size-lg mer-border" style={{'border-radius':'2em', 'border-width':'2px'}}>
                    <div className="col-12 p-0 mer-text--size-lg">
                        <div className="content text-center">
                            <h5 className="mer-color--primary-cyan d-inline-block mer-bg--ui-light mer-px-de" style={{'position':'relative', 'top':'-0.75em'}}>Why choose Kleenheat?</h5>
                        </div>
                    </div>
                    
                    <div class="col-12 col-md-10 col-lg-4">
                        <div class="row justify-content-center">
                            <div class="col-4 col-sm-3 col-md-3 col-lg-6 mer-px-de">
                                <Image className="mer-panel__image"
                                       src="/icons/flowmoji/flowmoji-wa.svg"
                                       alt="Flowmoji Western Australia"
                                />
                            </div>
                            <div class="col-8 col-sm-6 col-md-8 col-lg-9 text-lg-center">
                                <h5 class="my-0">WA born and bred</h5>
                                <p>We’ve been providing energy to Western Australian communities for over 65 years.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-10 col-lg-4">
                        <div class="row justify-content-center">
                            <div class="col-4 col-sm-3 col-md-3 col-lg-6 mer-px-de">
                                <Image className="mer-panel__image"
                                       src="/flybuys-points-1.png"
                                       alt="Flybuys 1 point per $1 spent"
                                />
                            </div>
                            <div class="col-8 col-sm-6 col-md-8 col-lg-9 text-lg-center">
                                <h5 class="my-0">Collect Flybuys points</h5>
                                <p>Link your Flybuys number to collect one point for every dollar you spend on natural gas.</p>
                                <small>
                                    <LinkModal modalContents={modalContents}
                                               useModal="true"
                                               displayContent="Terms and conditions apply"
                                               title="Flybuys terms & conditions"
                                               modalTitle="Flybuys terms & conditions"
                                               modalContentName="flybuys-terms"
                                               okayButtonLabel="Close"
                                    />
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-10 col-lg-4">
                        <div class="row justify-content-center">
                            <div class="col-4 col-sm-3 col-md-3 col-lg-6 mer-px-de">
                                <img className="mer-panel__image"
                                    src={racLogo}
                                    alt="RAC member benefits"
                                />
                            </div>
                            <div class="col-8 col-sm-6 col-md-8 col-lg-9 text-lg-center">
                                <h5 class="my-0">RAC member benefits</h5>
                                <p>Get an extra 3% off natural gas usage charges when you link your RAC membership number.</p>
                                <p>
                                    <small>
                                        <LinkModal modalContents={modalContents}
                                                useModal="true"
                                                displayContent="Terms and conditions apply"
                                                title="RAC offer terms and conditions"
                                                modalTitle="RAC offer terms and conditions"
                                                modalContentName="rac-ng-terms"
                                                okayButtonLabel="Close"
                                        />
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPanel>
    );
}

export default HomeProductHighlight;