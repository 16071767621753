import React, { useState, useEffect } from "react";
import { isAfter } from "date-fns";

import Layout from "components/layout/layoutPanel";
import Panel from "components/common/Panel";
import TileCard from "components/common/TileCard-new";
import SEO from "components/Seo";

import panelData from "constants/homepage/panel-new";
import tileCardData from "constants/homepage/tile-card-new";
import HomepageHeroBanner from "components/cards/HomepageHeroBanner-new";
import HomeProductHighlight from "components/cards/HomeProductHighlight";
import AwardsInfo from "components/cards/AwardsInfo";
import NgRacPromoInfo from "components/cards/NgRacPromoInfo";
import { Svg } from 'kh-common-components';
import heroSkateboard from 'images/moving-house/hero-skateboard-boxes.png';

// style
// bespoke styles for this page
// https://www.gatsbyjs.com/docs/how-to/styling/css-modules
import 'styles/pages/homepage-new.css';

function Homepage() {
  const [isPromoRac50Active, setIsPromoRac50Active] = useState(false);
  
  useEffect(() => {

    const currentDate = new Date();

    // racbonus 50
    const promoRac50Start = process.env.RACBONUS50_START.split('-');
    const promoRac50End = process.env.RACBONUS50_EXPIRE.split('-');
        
    const promoRac50StartDate = new Date(promoRac50Start[0], promoRac50Start[1]-1, promoRac50Start[2]);
    const promoRac50EndDate = new Date(promoRac50End[0], promoRac50End[1]-1, promoRac50End[2], promoRac50End[3] ? promoRac50End[3] : '', promoRac50End[4] ? promoRac50End[4] : '');

    const isPromoRac50Started = isAfter(currentDate, promoRac50StartDate);
    const isPromoRac50Ended = isAfter(currentDate, promoRac50EndDate);

    if (isPromoRac50Started && !isPromoRac50Ended) {
        setIsPromoRac50Active(true);
    }
  }, []);

  return (
    <>
      <SEO  title="Kleenheat"
            description="We're WA & the NT's local gas supplier, with over 65 years experience. Enjoy award-winning, local service and great ongoing value with Kleenheat." />

      <HomepageHeroBanner />

      <Layout theme="mer-theme--light"
              padding="mer-pt-lg mer-pb-sm feature-links"
              inlineStyle={{'background':'rgba(0,158,222,0.25)'}}
              id="homepage-tile-card">
        <div className="container mer-pt-de">
          <div className="row mer-panel align-items-center justify-content-around flex-direction-column flex-direction-md-row">
            <div class="col-12 text-center">
              <h4 class="my-0">Looking for something else?</h4>
            </div>
            <TileCard data={tileCardData} />
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-sm-8 text-md-center">
              <a class="d-inline-block mer-p-sm hero-notice" href="/lpg">
                <div class="d-flex align-items-center justify-content-center text-left">
                  <Svg title="Alert" 
                            icon="alert-jazzy"
                            aria-hidden="true"
                            style={{'color':'inherit', 'width':'1.5em', 'height':'1.5em'}} />
                  <h6 class="my-0 mer-pl-sm" style={{'color':'inherit'}}>Service changes for LPG customers. <span class="mer-text--no-wrap">Click here to learn more.</span></h6>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Layout>

      {/* Why choose kleenheat */}
      <HomeProductHighlight panelBackground="mer-bg--ui-light"
                            panelPadding="mer-pt-lg" />

      {/* Moving house made easy */}
      <Layout background="mer-bg--ui-light" padding="mer-py-ju">
        <div className="container">
          <div class="row mer-panel feature-pill">
              <div class="col-10 col-xl-5 col-md-6 col-sm-7 content mer-py-lg text-center mer-text--size-lg">
                  <h2>Moving house made easy</h2>
                  <p class="mer-color--primary-royal">New to Kleenheat? Simply tell us where you're moving and we'll take care of the rest. If you're an existing customer you can move your gas online through <a href="/my-account">My Account</a>.</p>
                  <div class="mer-panel__actions mer-button-group justify-content-center">
                      <a href="/moving-house" title="Move now" class="mer-button mer-button--primary" target="">Move your gas today</a>
                  </div>
              </div>
              <div class="col-6 col-xl-5 col-lg-4 col-md-5 col-sm-3 mer-panel__images feature-pill-asset ">
                <img className="mer-panel__image"
                     src={heroSkateboard}
                     alt="Moving house made easy"
                />
              </div>
          </div>
        </div>
      </Layout>

      <AwardsInfo
        panelBackground="mer-bg--ui-light"
        panelPadding="mer-pb-lg"
      />

      <NgRacPromoInfo
        theme={`mer-theme--cyan${isPromoRac50Active ? ' d-block': ' d-none'}`}
        background="mer-bg--primary-cyan"
        padding="mer-py-lg" />

      <Layout background="mer-bg--ui-light" theme="mer-theme--light" padding="mer-py-lg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg">
              <div class="content text-center">
                <h2 class="mer-color--primary-cyan">Other things we do</h2>
              </div>
            </div>
            <div className="col-10 col-lg-8">
              <Panel data={panelData} id="homepage1" />
              <Panel data={panelData} id="homepage2" />
              <Panel data={panelData} id="homepage3" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Homepage;
