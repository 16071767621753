import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import AddressSearchInput from "components/common/AddressSearchInput"
import Image from 'components/common/Image';
import heroBannerQeenheat from 'images/homepage/queenheat.png';
import heroBannerLilslip from 'images/homepage/lilslip.png';

import { isLpgDemergerOn } from 'constants/global';

function HomepageHeroBanner (props) {

    return (
        <>
            <LayoutPanel //theme="mer-theme--cyan"
                         padding="mer-pt-lg"
                         background="mer-bg--primary-cyan"
                         id="homepage-hero-banner">
                <div className="container">
                    <div className="row mer-panel align-items-end justify-content-around mer-theme--cyan">
                        <div className="col-10 col-sm-8 col-md-8 col-lg-7 col-xl-6 mer-text--size-lg">
                            <div className="content text-center">
                                <h1>No song and dance,<br/> just great value gas.</h1>
                                <h5>Enjoy award-winning local service and ongoing value with WA’s born and bred natural gas provider.</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mer-panel align-items-end justify-content-center mer-mt-de feature-row">
                            <div className="col-12 col-sm-10 col-md-9 col-lg-7 mer-card mdc-card p-relative hero-searchbox">
                                <form className="my-0 mer-py-de mer-px-lg text-center">
                                    <fieldset className="mer-text--size-lg">
                                        <legend>Sign up with us in a few easy steps.</legend>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-lg-11">
                                                <AddressSearchInput searchButtonText="Search"
                                                                    showLabel="false"
                                                                    isSsfRedirect={isLpgDemergerOn} />
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div className="d-flex align-items-center justify-content-center content" style={{'margin-bottom':'-1em'}}>
                                        <p className="my-0" style={{'font-weight':'500'}}>No account setup or exit fees</p>
                                        <Image className="my-0 mer-ml-sm" style={{'width':'20%', 'max-width':'3em'}} src="/icons/flowmoji/flowmoji-sunglasses.svg" alt="sunglasses" />
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 col-sm-10 col-md-9 col-lg-7 d-flex align-items-end hero-feature">
                                <div className="hero-left">
                                    <img className="mer-panel__image" src={heroBannerQeenheat} alt="queenheat"/>
                                </div>
                                <div className="hero-right">
                                    <img className="mer-panel__image" src={heroBannerLilslip} alt="lilslip"/>
                                </div>
                            </div>
                        </div>
                </div>
            </LayoutPanel>
        </>
    );
}

export default HomepageHeroBanner;